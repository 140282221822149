import React, { Component } from "react";
import Sketch from "react-p5";
import { Button } from "@material-ui/core";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import { createTheme } from '@material-ui/core/styles'

import About from "./About";
import HomePage from "./HomePage";
import Bar from "./Bar";
import Back from "./back.js";
import Contact from "./Contact";
import Staff from "./Staff";
import News from "./News";
import Law from "./Law";
import Interests from "./Interests";
import Column from "./Column";

import EnAbout from "./EnAbout";
import EnHomePage from "./EnHomePage";
import EnStaff from "./EnStaff";
import EnInterests from "./EnInterests";
import EnContact from "./EnContact";
import EnNews from "./EnNews";
/*
import EnBar from "./en/Bar";
import EnBack from "./en/back.js";
import EnLaw from "./en/Law";
*/
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Test from "./test"
import ScrollToTop from "./scroll";
import { useButtonStyles, useTypoStyles } from "./styles";
import MediaQuery from 'react-responsive';
import {BARTHRESHOLD} from "./data"

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
		sx={{ position: 'fixed', bottom: 108, right: 48}}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  //window: PropTypes.func,
};

//export default class App extends Component{
export default function App(){
	const setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight * 2).parent(
      canvasParentRef
    );
    //p5.frameRate(this.fr);
  };
  const draw = (p5) => {
	background(196, 215, 242);

  stroke(255, 180);
  line(0, 0, 25, 400);
  line(0, 0, 50, 400);
  line(0, 0, 75, 400);
  line(0, 0, 100, 400);


  line(0, 25, 125, 400)
  line(0, 50, 150, 400)
  line(0, 75, 175, 400)
  line(0, 100, 200, 400)
  line(0, 125, 225, 400)
  line(0, 150, 250, 400)
  line(0, 175, 275, 400)

  line(0, 200, 300, 400)
  line(0, 225, 325, 400)
  line(0, 250, 350, 400)
  line(0, 275, 375, 400)
  line(0, 300, 400, 400)
  line(0, 325, 400, 400)
  line(0, 350, 400, 400)
  line(0, 375, 400, 400)
  };
  //render(){
	const buttonClasses = useButtonStyles();
	const typoClasses = useTypoStyles();
	const THRESHOLD = BARTHRESHOLD;

  return (
    <React.Fragment>
	<span>
	<CssBaseline />
	<Router>
      <div className="App">
	  <Helmet>
                <meta charSet="utf-8" />
				<meta name="description" content="寶來資產扎根台灣放眼全球，成為大中華私募股權領導品牌。為投資者，被投資公司與社會創造最大利益與縱效。" />
				<meta name="keywords" content="寶來,寶來資產,投資,基金,股票,企業,資產管理,交易,股市" />
                <title>寶來資產</title>
				<link rel="icon" href="/static/images/logo.png" type="image/png"/>
            </Helmet>
		<ElevationScroll>
        <Bar />
		</ElevationScroll>
		<Toolbar id="back-to-top-anchor" />
		<div>
		<ScrollToTop />
		  <Switch>
		  <Route exact path="/law">
			<Law />
		  </Route>
		  <Route path="/staff">
			<Staff />
		  </Route>
		  <Route exact path="/news">
			<News />
		  </Route>
		  <Route exact path="/contact">
			<Contact />
		  </Route>
		  <Route path="/investment">
			<Interests />
		  </Route>
		  <Route exact path="/about">
			<About />
		  </Route>
		  <Route path="/column">
			<Column />
		  </Route>
		  <Route exact path="/home">
			<Back />
		  </Route>

		  <Route exact path="/en/law">
			<Law />
		  </Route>
		  <Route path="/en/staff">
			<EnStaff />
		  </Route>
		  <Route exact path="/en/news">
			<EnNews />
		  </Route>
		  <Route exact path="/en/contact">
			<EnContact />
		  </Route>
		  <Route path="/en/investment">
			<EnInterests />
		  </Route>
		  <Route exact path="/en/about">
			<EnAbout />
		  </Route>
		  <Route exact path="/en/home">
			<Back />
		  </Route>
		  <Route path="/en">
			<EnHomePage />
		  </Route>
		  <Route path="/">
			<HomePage />
		  </Route>
		  </Switch>
		</div>
		<div>
		<Switch>
		<Route path="/en">
		<AppBar position="static" style={{ background: "#000012"}}>
		<MediaQuery minWidth={THRESHOLD + 1}>
        <Toolbar style={{minHeight: "80px"}}>
		<Button
		style={{ marginRight: "auto"}}
		className={buttonClasses.underline}
		component={Link}
		to="/law">
		<p style={{fontSize: 20}}>
		Laws
		</p>
		</Button>
		<p style={{ fontSize: 20, marginLeft: "auto"  }}>
		Polaris 寶來
		</p>
		</Toolbar>
		</MediaQuery>
		<MediaQuery maxWidth={THRESHOLD}>
		<Toolbar style={{minHeight: "60px"}}>
		<Button
		style={{ marginRight: "auto"}}
		className={buttonClasses.underline}
		component={Link}
		to="/law">
		<p style={{fontSize: 16,}}>
		Laws
		</p>
		</Button>
		<p style={{ fontSize: 16, marginLeft: "auto"  }}>
		Polaris 寶來
		</p>
		</Toolbar>
		</MediaQuery>
		</AppBar>	

		</Route>
		

		<Route path="/">
		<AppBar position="static" style={{ background: "#001020"}}>
		<MediaQuery minWidth={THRESHOLD + 1}>
        <Toolbar style={{minHeight: "80px"}}>
		<Button
		style={{ marginRight: "auto"}}
		className={buttonClasses.underline}
		component={Link}
		to="/law">
		<p style={{fontSize: 20}}>
		法律聲明
		</p>
		</Button>
		<p style={{ fontSize: 20, marginLeft: "auto"  }}>
		Polaris 寶來
		</p>
		</Toolbar>
		</MediaQuery>
		<MediaQuery maxWidth={THRESHOLD}>
		<Toolbar style={{minHeight: "60px"}}>
		<Button
		style={{ marginRight: "auto"}}
		className={buttonClasses.underline}
		component={Link}
		to="/law">
		<p style={{fontSize: 16}}>
		法律聲明
		</p>
		</Button>
		<p style={{ fontSize: 16, marginLeft: "auto"  }}>
		Polaris 寶來
		</p>
		</Toolbar>
		</MediaQuery>
		</AppBar>
		</Route>
		
		</Switch>
		</div>
		
		<ScrollTop>
        <Fab style={{backgroundColor: "#004080"}} size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
        </ScrollTop>
      </div>
    </Router>
	</span>
	</React.Fragment>
  );
}
			
