import React from "react";
import { Link } from "react-router-dom";

import Typography from '@mui/material/Typography';
import {useState} from "react";
import { withStyles } from "@material-ui/core/styles";

import Sketch from "react-p5";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import PlaylistPlayTwoToneIcon from "@material-ui/icons/PlaylistPlayTwoTone";
import PlaylistAddTwoToneIcon from "@material-ui/icons/PlaylistAddTwoTone";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import {ReactP5Wrapper} from 'react-p5-wrapper';

import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from "@material-ui/core/styles";

import MediaQuery from 'react-responsive'
import MenuIcon from '@material-ui/icons/Menu';

import PageHead from "./PageHead";
import {THRESHOLD} from "./data";

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}


function Aboutpowerup(){
	
	const typoClasses = useTypoStyles();
	return (
	<Box style={{
	background: "white", 
	opacity: 0.9,
	height:"auto", 
	width: "100%", 
	alignItems: 'center', 
	justifyContent: 'center', 
	display: 'flex', 
	margin: 'auto'}}>
	<Box style={{width: "80%"}}>
	
	<p className={typoClasses.text}>
	連接頂尖投資者與高價值企業
	</p>
	<p className={typoClasses.text}>
	綜觀近代資本主義三百年，即使在這個專業化分工的時代，股市仍然沒有專家，只有贏家和輸家，把自己的珍貴財富交給真正貼近股市的投資團隊才是在這個時代最正確的選擇。
	（連接副標）
	</p>
	<p className={typoClasses.text}>
	公司願景
	</p>
	<p className={typoClasses.text}>
	扎根台灣，放眼全球，成為大中華私募股權領導品牌，為投資者，被投資公司與社會創造最大利益與縱效。
	（願景副標）
	</p>
	<p className={typoClasses.text}>
	1.實現卓越穩健的投資回報
	我們專注於自身擅長的產業與標的，搭配強大顧問團隊與產業先進最貼近實務的觀察與分析，謹慎選擇投資標的，謀取最佳回報。
	</p>
	<p className={typoClasses.text}>
	2.與企業和投資者建立長期夥伴關係
	我們與投資者建立長期夥伴關係，講求誠信並致力於追求卓越，從不斷挑戰中冶鍊自我，成長茁壯，將員工利益與投資者利益結合，同舟共濟。
	</p>
	<p className={typoClasses.text}>
	3.推動未來前瞻性發展
	專注當下，放眼未來，我們以半世紀後的角度審視我們的投資選擇是否正確可行，創造可持續性投資機會與發展，為下個世代謀取福利。
	</p>
	<p className={typoClasses.text}>
	綜觀近代資本主義三百年，即使在專業化分工的時代，股市仍然沒有專家，只有贏家和輸家，在這腳步緊湊、瞬息萬變的現代社會，在股市兼差真的有辦法獲取穩定的收益嗎？還是只能人云亦云的隨意投機呢？
<br/>
<br/>
	把自己的珍貴財富交給真正貼近股市的投資團隊才是在這個時代最正確的選擇，我們專注於自身擅長的產業與標的，搭配強大顧問團隊的總經資訊判讀與產業先進最貼近實務之觀察與頗析，進而用不同的邏輯與思維理解各項重要訊號與指標，結合產、官、學的全面角度審視每件投資標的，釐清股市中各種迷霧與誘惑手法，精準出擊後穩穩地將獲利收入囊中，如同商標上的三叉戟平穩地放在蝙蝠的翅膀上（與福同音），為投資者在控管一定風險下謀取最大福利並在資本市場中取勝。
<br/>
<br/>
「克猷無止境，內外在富強」是我們不變的核心價值，更代表我們經營團隊的堅韌精神與良好素質，在這瞬息萬變的資本社會，擁有克敵制勝的謀略才能在市場中取勝，保持內在與外在的強盛，共同創造出富強穩健的大平台，為股東帶來穩定的財富是我們永遠不變的經營方針，我們更以此為榮。

</p>
</Box>
</Box>
);
}

export function sketch (p) {
	p.setup = function () {
        p.createCanvas(window.innerWidth, 300);
    };

    p.draw = function () {
		p.background(255);
		p.noStroke();
		p.fill(100);
		p.rotate(-1);
		p.rect(-1.8 *  window.innerWidth, 0, 2 * window.innerWidth, 2 * window.innerWidth);
    };
}
export function sub (p) {
	p.setup = function () {
        p.createCanvas(window.innerWidth, 500);
		p.noLoop();
	};

    p.draw = function () {
		p.background(255);
		p.noStroke();
		p.fill(50);
		p.rect(0, 0, 0.4 * window.innerWidth, 2 * window.innerWidth);
		p.textSize(36);
		p.fill(200);
		p.text('連接頂尖投資者與高價值企業', 0.1 * window.innerWidth, 100);
		p.textSize(20);
		p.fill(100);
		p.text('綜觀近代資本主義三百年，即使在這個專業化分工的時代，\n股市仍然沒有專家，只有贏家和輸家，\n把自己的珍貴財富交給真正貼近股市的投資團隊才是在這個時代最正確的選擇。', 0.45 * window.innerWidth, 100);
    };
}

const useStyles = makeStyles(theme => ({
  cardRootStyle: {
	margin: "auto",
	width: "auto",
	height: "auto",
  },
  cardMediaStyle: {
    paddingTop: "100%",
  }
}));

const Card1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: 'center',
  background: 'black',
  margin: "auto",
  color: theme.palette.text.secondary,
}));
const Card2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  textAlign: 'center',
  background: '#DDDDDD',
  margin: "auto",
  color: theme.palette.text.secondary,
}));
const Card3 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  textAlign: 'center',
  background: 'white',
  border: "none",
  margin: "auto",
  color: theme.palette.text.secondary,
}));
const Card4 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  border: "none",
  height: "100%",
  textAlign: 'center',
  background: 'white',
  margin: "auto",
  color: theme.palette.text.secondary,
}));

export default function About() {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();
  const styles = useStyles();
  /*
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, 200).parent(
      canvasParentRef
    );
    //p5.frameRate(this.fr);
  };
  const draw = (p5) => {
	p5.background(255);
	p5.noStroke();
	p5.fill(100);
	p5.rect(0, 0, 1000, 1000);
	p5.fill(200);
	p5.textSize(64);
	p5.text('關於勁揚', 100, 100);
  };
	*/
  
  return (
    <React.Fragment>
		
	  <Box
        position="relative"
        height="auto"
        className={backgroundClasses.main}
      >
	  <StyleRoot>
	  <PageHead title="About Polaris"/>
		
		<div style={{
			height: "auto",
			animation: 'x 1s',
			animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}}
			>
			<MediaQuery minWidth={THRESHOLD + 1}>
			<div style={{width: "90%"}}>
			<Grid container spacing={0} style={{height: 500}}>
				<Grid item xs={5}>
							<h1 align="left" style={{fontSize: 30, marginRight: "10%",

							marginLeft: 60,
							color: "#000030",
							fontFamily: "Serif",
							}}>
							Connect the top investors with valuable enterprises
							</h1>
				</Grid>
				<Grid item xs={0.4}>
					
				<div style={{width: 2, height: "100%", background: "#0068a0"}}>
				</div>
				</Grid>
				<Grid item xs={6.6}>
							<h2 align="left" style={{fontSize: 22, marginLeft: "5%",
							fontFamily: "Serif",
							}}>
							In times of professional services, <br/>
there are no experts but only winners and losers. <br/>
With the most comprehensive and constructed perspectives, our team is the best choice for investment.
							</h2>
				</Grid>
					
			</Grid>
			</div>
			<Box
				sx={{
				top: -300,
				position: "relative",
				}}
				style={{
				alignItems: 'center',
				justifyContent: 'center',
				marginBottom: -200
				}}
				>
				<img
				style={{ width: "60%",
						position: "relative",
						}}
				src="/static/images/city.jpg"
						/>

			</Box>
			</MediaQuery>
			<MediaQuery maxWidth={THRESHOLD}>
				<div style={{width: "90%", margin: "auto"}}>
							<h1 align="left" style={{fontSize: 25,
							fontFamily: "Serif",
							color: "#000030",	
							}}>
							Connect the top investors with valuable enterprises
							</h1>
							<div style={{width: "100%", height: 2, background: "#0068a0", margin: "auto"}}></div>
							<h2 align="left" style={{fontSize: 18,
							fontFamily: "Serif",
							}}>
							In times of professional services, <br/>
there are no experts but only winners and losers. <br/>
With the most comprehensive and constructed perspectives, our team is the best choice for investment.
							</h2>
							</div>
			<Box
				sx={{
				position: "relative",
				}}
				style={{
				alignItems: 'center',
				justifyContent: 'center',
				}}
				>
				<img
				style={{ width: "100%",
						position: "relative",
						}}
				src="/static/images/city.jpg"
						/>

			</Box>
			</MediaQuery>
			<div style={{
				background: "white",
				opacity: 0.8,
				height: "auto", 
				width: "60%", 
				marginLeft: 'auto',
				marginRight: 'auto',
				textAlign: "left",
				marginTop: 'auto',
				marginBottom: 50,
				}} 
				>
			<MediaQuery minWidth={THRESHOLD + 1}>
				
				<Box style={{display: "flex"}}>
			<Grid container spacing={2} style={{marginTop: "2%"}}>
				<Grid item xs={4}>
				<h1 style={{
				color: "black",
				textAlign: "center",
				marginTop: 0,
				fontSize: 44,
				fontFamily: "Serif",
				fontStyle: "normal",
				}}>
				Our Vision
				</h1>
				</Grid>
				<Grid item xs={8}>
				<h1 style={{
				marginLeft: "auto",
				marginTop: 0,
				color: "#444444",
				fontSize: 20,
				textAlign: "center",
				fontFamily: "Serif",
				fontStyle: "normal",
				}}>
				We devote to being a supereme Taiwan-based global firm,
				<br/>
				and trying our best to create the most profits for investors, enterprises and the society.
				</h1>
				</Grid>
				</Grid>
				</Box>
			</MediaQuery>
			<MediaQuery maxWidth={THRESHOLD}>
			<div style={{height: 50}}></div>
				<Box>
				<h1 style={{
				color: "black",
				marginTop: 0,
				fontSize: 30,
				fontFamily: "Serif",
				fontStyle: "normal",
				}}>
				Our Vision
				</h1>
				<h1 style={{
				marginLeft: 0,
				marginTop: 0,
				color: "#444444",
				fontSize: 18,
				fontFamily: "Serif",
				fontStyle: "normal",
				}}>
				We devote to being a supereme Taiwan-based global firm,
				and trying our best to create the most profits for investors, enterprises and the society.
				</h1>
				</Box>	
			</MediaQuery>
			<Propcard 
			title="Gain incredible profits"
			text="Based on robust management teams, as well as the most pratical and professional perspectives, we select industries with best cautiousness and makes optimal profits."/>			
			<div style={{height: 10}}/>
			<Propcard 
			title="Establish collaborative relationships"
			text="We establish long-term collaborative relationships with investors. Valuing integrity and pursuing excellence, we conquer every challenges and stride forward success with investors."/>			
			<div style={{height: 10}}/>
			<Propcard 
			title="Promote farsighted development"
			text="Focusing on presense and being cautious to the future, we examine our every decision on investment in a long-term perspective. We create innumerable opportunities for the next generation."/>			
			</div>		

		</div>
	  </StyleRoot>
      </Box>
	</React.Fragment>
  );
}

function Propcard(props){
	return (
	<>
			<Grid container spacing={2} style={{marginTop: "2%"}}>
			<MediaQuery minWidth={THRESHOLD + 1}>
				<Grid item xs={4}>
					<Card1>
						<CardContent>
						<h1 style={{color: "white", fontSize: 20, fontFamily: "Serif"}}>
							{props.title}
							</h1>
						</CardContent>
					</Card1>
				</Grid>
				<Grid item xs={8}>
					<Card2>
						<CardContent>
							<h1 style={{color: "black", fontSize: 18, fontFamily: "Serif"}}>
							{props.text}
							</h1>
						</CardContent>
					</Card2>
				</Grid>
			</MediaQuery>
			<MediaQuery maxWidth={THRESHOLD}>
					<Card1 style={{width: "100%"}}>
						<CardContent>
						<h1 style={{color: "white", fontSize: 18, fontFamily: "Serif"}}>
							{props.title}
							</h1>
						</CardContent>
					</Card1>
					<Card2 style={{width: "100%"}}>
						<CardContent>
						<h1 style={{color: "black", fontSize: 16, fontFamily: "Serif"}}>
							{props.text}
							</h1>
						</CardContent>
					</Card2>

			</MediaQuery>
			</Grid>
	</>
	);
}
