import React from "react";

import Typography from '@mui/material/Typography';
import { withStyles } from "@material-ui/core/styles";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from '@mui/material/Avatar';

import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import PlaylistPlayTwoToneIcon from "@material-ui/icons/PlaylistPlayTwoTone";
import PlaylistAddTwoToneIcon from "@material-ui/icons/PlaylistAddTwoTone";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PropTypes from "prop-types";

import img_ja from "./images/ja.png";
import {people} from "./data.js";
import useWindowDimensions from "./window.js";
import { makeStyles } from "@material-ui/core/styles";
import Test from "./test";
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
import {THRESHOLD} from "./data";
import PageHead from "./PageHead";

const useStyles = makeStyles(theme => ({
  cardRootStyle: {
    minHeight: "auto",
    maxHeight: "auto",
  },
  cardMediaStyle: {
    paddingTop: "100%",
  }
}));

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}


const Card1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "transparent",
  border: "none",
	boxShadow: "none",
	marginTop: 30,
	marginBottom: 30,
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Card2 = styled(Paper)(({ theme }) => ({

  padding: theme.spacing(1),
  borderRadius: 0,
  background: "#f0f0f0",
  borderShadow: "none",
  borderColor: "none",
  height: "100%",
  textAlign: 'center',

}));


export default function Staff(props) {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();
  let {height , width } = useWindowDimensions();
  return (
    <React.Fragment>

	  <StyleRoot>
        <div style={{
			animation: 'x 1s',
			animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
		}}>
		<Switch>	
          <Route path="/en/staff/liu" render={(props) => (
			<Profile name={"劉軒綸"}/>
			)} /> 
          <Route path="/en/staff/chiang" exact render={(props) => (
			<Profile name={"江立中"}/>
			)} /> 
          <Route path="/en/staff/you" exact render={(props) => (
			<Profile name={"游張松"}/>
			)} /> 
          <Route path="/en/staff/huang" exact render={(props) => (
			<Profile name={"黃進益"}/>
			)} /> 
          <Route path="/en/staff/yeh" exact render={(props) => (
			<Profile name={"葉美麗"}/>
			)} /> 
          <Route path="/en/staff/lijen" exact render={(props) => (
			<Profile name={"李仁智"}/>
			)} /> 
          <Route path="/en/staff/huang" exact render={(props) => (
			<Profile name={"黃進益"}/>
			)} /> 
		  <Route path="/en/staff/tsai" exact render={(props) => (
			<Profile name={"蔡裕平"}/>
			)} /> 
          <Route exact path="/en/staff" component={Main}/>
		</Switch>	
		</div>
	  </StyleRoot>
	</React.Fragment>
  );
}

Staff.propTypes = {
  imagePath: PropTypes.string
};

function Main(){
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();
	//const { H, W } = useWindowDimensions();
	const H = 1000, W = 2000;
	const THRESHOLD = 1000;
  const styles = useStyles();
	return(
	<>
        <PageHead title="STAFF"/>

		<div style={{background: "#f0f0f0"}}>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		<MediaQuery minWidth={1400 + 1}>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 50, color: "#000020"}}> Management Team </h1>
		  </MediaQuery>
		  <MediaQuery maxWidth={1400}>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 35, color: "#000020"}}> Management Team </h1>
		  </MediaQuery>
		  <div style={{height: 1, align: "center", width: "90%", background: "#000020",
		  marginLeft: "5%",
		  marginRight: "5%",
		  }}/>
		  </div>
		<div style={{background: "#f0f0f0", height: 100}}/>

	<Box style={{background: "#f0f0f0", height: "auto"}}>
		<div 
			style={{
				width: "100%",
				animation: 'x 1s',
				animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}
		}>
	<Box 
		style={{
			width: "90%",
			alignItems: "center",
			justifyContent: 'center', 
			margin: 'auto',
		}}
	>
	<MediaQuery minWidth={1400 + 1}>	
      <Grid container spacing={2}>
        <Grid item xs={3}>
		  <ProfileButton name="葉美麗"/>
        </Grid>
        <Grid item xs={3}>
		  <ProfileButton name="游張松"/>
        </Grid>
		<Grid item xs={3}>
		  <ProfileButton name="劉軒綸"/>
        </Grid>
        <Grid item xs={3}>
		  <ProfileButton name="江立中"/>
        </Grid>
        </Grid>
	</MediaQuery>	
	<MediaQuery maxWidth={1400}>	
		  <ProfileButton name="葉美麗"/>
		  <ProfileButton name="游張松"/>
		  <ProfileButton name="劉軒綸"/>
		  <ProfileButton name="江立中"/>
	</MediaQuery>	
	</Box>	

			
			<div style={{background: "#f0f0f0"}}>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		<MediaQuery minWidth={1400 + 1}>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 50, color: "#000020"}}> Consultant Team </h1>
		  </MediaQuery>
		  <MediaQuery maxWidth={1400}>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 35, color: "#000020"}}> Consultant Team </h1>
		  </MediaQuery>
		  <div style={{height: 1, align: "center", width: "90%", background: "#000020",
		  marginLeft: "5%",
		  marginRight: "5%",
		  }}/>
		  </div>
		  <div style={{background: "#f0f0f0", height: 100}}/>
			
	<Box 
		style={{
			width: "90%",
			alignItems: "center",
			justifyContent: 'center', 
			margin: 'auto',
		}}
	>	
	<MediaQuery minWidth={1400 + 1}>	
      <Grid container spacing={2}>
        <Grid item xs={3}>
		  <ProfileButton name="黃進益"/>
        </Grid>
        <Grid item xs={3}>
		  <ProfileButton name="李仁智"/>
        </Grid>
		<Grid item xs={3}>
		  <ProfileButton name="蔡裕平"/>
        </Grid>
      </Grid>
	</MediaQuery>	
	<MediaQuery maxWidth={1400}>	
		  <ProfileButton name="黃進益"/>
		  <ProfileButton name="李仁智"/>
		  <ProfileButton name="蔡裕平"/>
	</MediaQuery>	
	</Box>
		</div>
	</Box>
	</>

	);
}

function Profile(props){
	const styles = useStyles();
	let person = people.find(item => item.name === props.name);
	let img_path = "/static/images/" + person.img;
	return (
		<Box style={{
		background: "#f0f0f0",
		height: "100%",
		}}>
			<div style={{background: "#f0f0f0"}}>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 50, color: "#000020"}}> {person.position} </h1>
		  
		  <div style={{height: 1, align: "center", width: "90%", background: "#000020",
		  marginLeft: "5%",
		  marginRight: "5%",
		  }}/>
		  </div>
		<div style={{background: "#f0f0f0", height: 100}}/>
	<MediaQuery minWidth={1400 + 1}>	
      <Grid container spacing={2} style={{
		width: "75%",
		background: "#f0f0f0",
			justifyContent: 'center', 
			margin: "auto",
	  }}>
        <Grid item xs={2}/>
        <Grid item xs={4}>
			<Card1>
		  <div style={{overflow: "hidden",
			height: 400,
			width: 300,
		  }}>
		  <img
		  style={{margin: "auto", width: "100%", height: 500, objectFit: "cover"}} 
		  src={img_path} />
		  </div>
			</Card1>
        </Grid>
        <Grid item xs={6}>
			<div>
						<ul>

					<h1 align="left" style={{
					justifyContent: "center", 
					display: "flex",
					color: "#000010",
					flexDirection: "column", height: "auto", fontSize: 32, fontStyle: "bold"}}>
						{person.name}
					</h1>
					<div style={{height: 1, background: "#000020"}}/>
					<h1 align="left" style={{fontSize: 24, color: "#000020", fontStyle: "bold"}}>
						{person.titles.map((data, idx) => (
							<p>{data.title}</p>
						))}
					</h1>
						</ul>
			</div>
        </Grid>
      </Grid>
	</MediaQuery>	
	<MediaQuery maxWidth={1400}>	
	<div style={{
	width: "60%",
	height: "100%",
	margin: "auto",
		background: "#f0f0f0"}}>
	<div style={{
	background: "#f0f0f0",
	display: "flex",
	justifyContent: "center",
	}}>
		  <div style={{overflow: "hidden",
			height: 400,
			width: 300,
		  }}>
		  <img
		  style={{marginTop: 50, 
		  marginLeft: "auto",
		  marginRight: "auto",
		  width: "100%", height: 400, objectFit: "cover"}} 
		  src={img_path} />
		  </div>
	</div>
	<div style={{
	background: "#f0f0f0",
	justifyContent: "center",
	}}>

		<div style={{
		}}>
					<h1 style={{
					color: "#000040",
					height: "auto", fontSize: 30}}>
						{person.name}
					</h1>
					</div>
					<div style={{margin: "auto", width: 300, height: 1, background: "#000010"}}/>
		<div style={{
		}}>
					<h1 style={{fontSize: 24, color: "#000010"}}>
						{person.titles.map((data, idx) => (
							<p>{data.title}</p>
						))}
					</h1>
					</div>
		</div>
		<div style={{height: 50}}/>
	</div>
	</MediaQuery>	
	  </Box>
	);	
}

function ProfileButton(props){
	const styles = useStyles();
	const buttonClasses = useButtonStyles();
	let person = people.find(item => item.name === props.name);
	let img_path = "/static/images/" + person.img;
	return(
		<>
		<StyleRoot>
		<div
		style={{
            animation: 'x 2s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
            }}>
		  <Button
		  className={buttonClasses.none}
		  style={{marginTop: "auto", width: "100%", height: "100%"}}
		  startIcon={<div>
		  <div style={{overflow: "hidden",
			height: 400,
			width: 300,
		  }}>
		  <img
		  style={{marginTop: 0, width: "100%", height: 500, 
		  
		  objectFit: "cover"
		  }} 
		  src={img_path} />
		  </div>
		  <h1 style={{color: "#000040", textAlign: "center", fontSize: 26
		  }}>{person.name}</h1>
		  <h1 style={{color: "#000010", textAlign: "center", fontSize: 30, marginTop: -20,
		  }}>{person.position}</h1></div>
		  }

		  component={Link} to={"/en/staff/" + person.link}/>
		  </div>
		</StyleRoot>
		</>
	);
}
