import React from "react";
import { Link } from "react-router-dom";

import Typography from '@mui/material/Typography';
import { withStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import PlaylistPlayTwoToneIcon from "@material-ui/icons/PlaylistPlayTwoTone";
import PlaylistAddTwoToneIcon from "@material-ui/icons/PlaylistAddTwoTone";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PropTypes from "prop-types";

import MediaQuery from 'react-responsive'
import MenuIcon from '@material-ui/icons/Menu';
import {THRESHOLD} from "./data";

import { makeStyles } from "@material-ui/core/styles";
import PageHead from "./PageHead";

const useStyles = makeStyles(theme => ({
  cardRootStyle: {
    minHeight: "auto",
    maxHeight: "auto",
  },
  cardMediaStyle: {
    paddingTop: "100%",
  }
}));

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}


const Card1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "white",
  border: "none",
	boxShadow: "none",
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Card2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "white",
  border: "none",
	boxShadow: "none",
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function About(props) {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        position="relative"
        height="auto"
		className={backgroundClasses.main}
      >
	  <StyleRoot>
        <PageHead title="CONTACT US"/>
		<div 
			style={{
				width: "100%",
				background: "#f0f0f0",
				height: "auto",
				animation: 'x 1s',
				animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}
		}>
	<MediaQuery minWidth={THRESHOLD + 1}>
	<Box 
		style={{
			width: "70%",
			margin: "auto",
		}}
	>
	<br/>
	<h1 style={{textAlign: "left", fontSize: 30}}>
		TEL: +886-2-2775-3118
	</h1>
	<h1 style={{textAlign: "left", fontSize: 30}}>
		E-mail：info20220113@polaris-am.com
	</h1>
	<br/>
	</Box>	
	</MediaQuery>
	<MediaQuery maxWidth={THRESHOLD}>
	<Box 
		style={{
			width: "80%",
			margin: "auto",
		}}
	>
	<br/>
	<h1 style={{textAlign: "left", fontSize: 20}}>
		TEL: +886-2-2775-3118
	</h1>
	<h1 style={{textAlign: "left", fontSize: 20}}>
		E-mail：info20220113@power-upcapital.com
	</h1>
	<br/>
	</Box>	
	</MediaQuery>
  
		</div>
	  </StyleRoot>
      </Box>
	</React.Fragment>
  );
}

About.propTypes = {
  imagePath: PropTypes.string
};

