export const people =[
	{
	name: "江立中",
	id: "chimic",
	link: "chiang",
	img: "chimic.jpeg",
	position: "資訊長",
	titles:[ 
		{
		title: "國立台灣大學資訊工程學系",
		},
		{
		title: "中華國際投融資促進會會員",
		},
		]
	},
	{
	name: "劉軒綸",
	id: "liualb",
	link: "liu",
	img: "liualb.jpg",
	position: "副總經理",
	titles:[
		{
		title: "勁揚資產管理顧問有限公司董事長",
		},
		{
		title: "美商亞洲高盛證卷資訊科技部門實習生",
		},
		{
		title: "國立台灣大學會計學系雙主修政治學系",
		},
		{
		title: "中華國際投融資促進會會員",
		},
		]
	},
	{
	name: "游張松",
	id: "youcha",
	link: "you",
	img: "you.jpg",
	position: "合夥人",
	titles:[ 
		{
		title: "國立臺灣大學資訊工程學系",
		},
		{
		title: "國立臺灣大學資訊管理學系創辦人暨教授",
		},
		{
		title: "美國卡內基美隆大學工業管理學院博士",
		},
		{
		title: "雄獅旅行社（股）公司獨立董事",
		},
		{
		title: "網路家庭國際資訊（股）公司獨立董事",
		},
		]
	},
	{
	name: "葉美麗",
	id: "yehmei",
	link: "yeh",
	img: "yehmei.jpg",
	position: "總裁",
	titles:[ 
		{
		title: "捷揚投資股份有限公司董事長",
		},
		{
		title: "寶來證卷集團創辦人",
		},
		{
		title: "北京大學光華管理學院博士班",
		},
		{
		title: "日本特許大學榮譽博士",
		},
		]
	},
	{
	name: "李定安",
	id: "litin",
	link: "li",
	img: "litin.jpg",
	position: "合夥人",
	titles:[ 
		{
		title: "建誠聯合會計師事務所 會計師",
		},
		{
		title: "台北市政府商業處 諮詢會計師",
		},
		{
		title: "經濟部投資審議委員會 諮詢會計師",
		},
		{
		title: "台北市產業發展局 諮詢會計師",
		},
		{
		title: "中華國際投融資促進會 監事",
		},
		{
		title: "國立臺灣大學會計學系",
		},
		]
	},
	{
	name: "曾天福",
	id: "tsetie",
	link: "tse",
	img: "fu.jpg",
	position: "合夥人",
	titles:[
		{
		title: "台灣角力協會副理事長",
		},
		{
		title: "展天貿易有限公司董事長",
		},
		]
	},
	{
	name: "黃進益",
	link: "huang",
	img: "huang.jpg",
	position: "顧問",
	titles:[ 
		{
		title: "香港光大益豐行基金管理有限公司 董事長",
		},
		{
		title: "美國富達基金投資管理公司 協理",
		},
		{
		title: "京華證券投資信託公司 經理",
		},
		{
		title: "中國民國工商建設研究會 財務長",
		},
		]
	},
	{
	name: "李仁智",
	link: "lijen",
	img: "lijen.JPG",
	position: "顧問",
	titles:[ 
		{
		title: "頤得先進股份有限公司 董事長",
		},
		{
		title: "友達光電股份有限公司 研發工程師",
		},
		{
		title: "宏達電子股分有限公司 射頻天線研發工程師",
		},
		{
		title: "國立成功大學/光電所 博士",
		},
		]
	},
	{
		name: "蔡裕平",
		link: "tsai",
		img: "tsayup.JPG",
		position: "顧問",
		titles:[ 
			{
			title: "美通創投管理公司 董事長",
			},
			{
			title: "統一安聯保險集團 策略執行長",
			},
			{
			title: "統一產物保險股份有限公司 總經理",
			},
			{
			title: "統一證券投資信託股份有限公司 總經理",
			},
			{
			title: "國際通商法律事務所 律師",
			},
			{
			title: "加州 Diepenbrock Wulff&Hannegan 法律事務所 律師",
			},
			{
			title: "國立中興大學法律系 副教授",
			},
			]
		},
]

export const THRESHOLD = 1200;
export const BARTHRESHOLD = 1200;