import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import {useState, useEffect } from 'react';
import { useButtonStyles, useTypoStyles } from "./styles";
import Test from "./test";
import useWindowDimensions from "./window"
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive';
import MenuIcon from '@material-ui/icons/Menu';
import {BARTHRESHOLD} from "./data"

export const useStyles = makeStyles({
  above: {
    flexGrow: 1,
    color: "#283747",
  },
  loginbutton: {
    marginLeft: "auto",
  },
});


export default function Bar() {
	const buttonClasses = useButtonStyles();
	const typoClasses = useTypoStyles();
	const barClasses = useStyles();
	function refreshPage() {
		window.location.reload(false);
	}
  
	const [show, setShow] = useState(false);
	const THRESHOLD = BARTHRESHOLD;
	let {height , width } = useWindowDimensions();
	let pageName = location.pathname;
	if (pageName.includes("/en")) {
	pageName = pageName.split("/en")[1]
	//console.log(pageName)
	return (
	<>
		<MediaQuery minWidth={THRESHOLD}>
			<AppBar style={{ background: "#f0f0f0"}}>
				<Toolbar>
					<IconButton className={buttonClasses.white} style={{ color: "white" }} component={Link} to="/en">
						<img
							style={{ maxWidth: 60, maxHeight: 60}}
							src="/static/images/logo.png" 
						/>
							<h1 style={{fontSize: 30, fontFamily: "Serif", color: "black", marginLeft: 10}}>Polaris Assets Management</h1>
					</IconButton>
					<div className={barClasses.loginbutton}>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/en/about"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						ABOUT
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/en/investment"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						INVESTMENT
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/en/staff"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						STAFF
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/en/news"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						NEWS
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/en/contact"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						CONTACT
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to={pageName}
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						Ch/En
						</p>
						</Button>
            </div>
          </Toolbar>
        </AppBar>
	  </MediaQuery>
	  <MediaQuery maxWidth={THRESHOLD}>
	  <AppBar style={{ background: "#f0f0f0",}}>
			<Toolbar style={{height: 90}}>

			<IconButton className={buttonClasses.white} style={{ color: "white" }} component={Link} to="/">
						<img
							style={{ maxWidth: 40, maxHeight: 40}}
							src="/static/images/logo.png" 
						/>
							<h1 style={{fontSize: 20, fontFamily: "Serif", color: "black", marginLeft: 10}}>Polaris Assets Management</h1>
					</IconButton>

            <div className={barClasses.loginbutton}>
				<Button className={buttonClasses.white} onClick={() => setShow(prev => !prev)}>
					<MenuIcon/>
				</Button>
            </div>
          </Toolbar>
		  

		<Box style={{}}>
		{show &&
		<>
		<div style={{overflow: "scroll", height: "100vh"}}>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/en/about"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                ABOUT
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/en/investment"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
				INVESTMENT
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/en/staff"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                STAFF
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/en/news"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                NEWS
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/en/contact"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                CONTACT
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
						<Button
							className={buttonClasses.white}
							component={Link}
							to={pageName}
				onClick={() => setShow(prev => !prev)}
						>
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
						Ch/En
						</p>
						</Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 120,
		}}
		></Box>
		</div>
		</>
		}
		</Box>
		</AppBar>
	  </MediaQuery>
	  </>
	);
	}
	//ifend	
	let greekPagePath = `/en${pageName}`
	console.log(greekPagePath)
	console.log(pageName)

	return (
	<>
		<MediaQuery minWidth={THRESHOLD + 1}>
			<AppBar style={{ background: "#f0f0f0"}}>
				<Toolbar>
					<IconButton className={buttonClasses.white} style={{ color: "white" }} component={Link} to="/">
						<img
							style={{ maxWidth: 60, maxHeight: 60}}
							src="/static/images/logo.png" 
						/>
							<h1 style={{fontSize: 30, fontFamily: "Serif", color: "black", marginLeft: 10}}>寶來資產管理</h1>
					</IconButton>
					<div className={barClasses.loginbutton}>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/about"
						>	
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						關於寶來
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/investment"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						投資組合
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/staff"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						經營團隊
						</p>
						</Button>
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/news"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						新聞資訊
						</p>
						</Button>
						
						<Button
							className={buttonClasses.white}
							component={Link}
							to="/contact"
						>
						<p
							style={{fontSize: 25, fontFamily: "Serif"}}
						>
						聯繫我們
						</p>
						</Button>

            </div>
          </Toolbar>
        </AppBar>
	  </MediaQuery>
	  <MediaQuery maxWidth={THRESHOLD}>
			<AppBar style={{ background: "#f0f0f0",}}>
			<Toolbar style={{height: 90}}>

			<IconButton className={buttonClasses.white} style={{ color: "white" }} component={Link} to="/">
						<img
							style={{ maxWidth: 40, maxHeight: 40}}
							src="/static/images/logo.png" 
						/>
							<h1 style={{fontSize: 20, fontFamily: "Serif", color: "black", marginLeft: 10}}>寶來資產管理</h1>
					</IconButton>

            <div className={barClasses.loginbutton}>
				<Button className={buttonClasses.white} onClick={() => setShow(prev => !prev)}>
					<MenuIcon/>
				</Button>
            </div>
          </Toolbar>
		  

		<Box style={{}}>
		{show &&
		<>
		<div style={{overflow: "scroll", height: "100vh"}}>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/about"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                關於寶來
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/investment"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
				投資組合
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/staff"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                經營團隊
				</p>
              </Button>
		</Box>
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/news"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                新聞資訊
				</p>
              </Button>
		</Box>
		
		<Box
		style={{
		color: "#000012",
		height: 60,
		}}
		>
              <Button
                className={buttonClasses.white}
                component={Link}
                to="/contact"
				onClick={() => setShow(prev => !prev)}
              >
						<p
							style={{fontSize: 20, fontFamily: "Serif"}}
						>
                聯繫我們
				</p>
              </Button>
		</Box>
		
		<Box
		style={{
		color: "#000012",
		height: 120,
		}}
		></Box>
		</div>
		</>
		}
		</Box>
		</AppBar>
        
	  </MediaQuery>
	  </>
  );
}
