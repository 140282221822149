import React from "react";
import { Link } from "react-router-dom";

import useWindowDimensions from "./window.js";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import { Player } from 'video-react';
import VideoPlayer from "react-background-video-player";
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'

import {THRESHOLD} from "./data"

const Card1 = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	borderRadius: 0,
	maxWidth: "100%",
	background: "transparent",
	border: "none",
	  boxShadow: "none",
	  marginTop: 30,
	  marginBottom: 30,
	height: "100%",
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));
  const Card2 = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	background: "transparent",
	border: "none",
	  marginTop: 100,
	  marginBottom: 100,
	  boxShadow: "none",
	height: "auto",
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));
  const Card3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	background: "transparent",
	border: "none",
	boxShadow: "none",
	height: "auto",
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));

  /*
  <div>
		  <video preload autoPlay loop muted playsinline
		style={{width: "100%", minWidth: "100%", objectFit: "contain", overflow: "hidden"}}
	  >
        <source
          src="/static/images/videohd.mov"
          type="video/mp4"
        />
      </video>
	  
		</div>
  */

export default function EnHomePage() {
	const buttonClasses = useButtonStyles();
	const typoClasses = useTypoStyles();
	const backgroundClasses = useBackgroundStyles();
	let {height , width} = useWindowDimensions();
  
	return (
	  <>
		  <div style={{height: 46, background: "#0068a0"}}/>
		  
		  
		<div
		  style={{
		  }}
		>

			<div style={{}}>

			<img
			style={{
				width: "100%", objectFit: "cover"}}
				src={"/static/images/pixta_85106858_L.jpg"}>
			</img>
		  
<MediaQuery minWidth={THRESHOLD + 1}>
		<div style={{position: "absolute", width: "100%", top: 0, zIndex:999,
		}}>
		<Box style={{background: "#0068a0", height: 250, 
		  marginTop: 110,
		clipPath: "polygon(0 0, 0% 100%, 100% 0)"}}>
		  <div style={{position: "absolute", height: "100%", width: "100%"}}>
			<div style={{position: "relative", display: "flex"}}>
			  <h1
				  style={{fontSize: 45, color: "white", fontFamily: "Serif", marginLeft: "5%"}}
			  >
			  Support Every Investor.
			  </h1>
			  </div>
			  </div>
			  </Box>
		</div>
		  </MediaQuery>
		  <MediaQuery maxWidth={THRESHOLD}>
		  <div style={{position: "absolute", width: "100%", top: 0, 
		}}>
		<Box style={{background: "#0068a0", height: 60, 
		  marginTop: 80,}}>

		  <div style={{position: "absolute", height: "100%", width: "100%"}}>
		  	<div style={{height: 20}}/>
		  	
			  <h1
				  style={{fontSize: 22, color: "white", fontFamily: "Serif"}}
			  >
			  Support Every Investor.
			  </h1>
			  </div>
			  </Box>
			  <Box style={{background: "#0068a0", height: 100, 
		clipPath: "polygon(0 0, 0% 100%, 100% 0)"}}>
			  </Box>
		</div>
		  </MediaQuery>
		</div>
		  <MediaQuery minWidth={THRESHOLD + 1}>
		<div style={{position: "relative", width: "100%", height: "100%"
		}}>
		<div style={{
		  marginTop: -310,
		position: "relative", width: "100%"}}>
  
		<Box style={{background: "#0068a0", height: 250, 
		clipPath: "polygon(100% 100%, 0% 100%, 100% 0)"}}/>
		<div style={{background: "#0068a0", marginTop: -100,
		clipPath: "polygon(0 100%, 100% 100%, 100% 0, 0 20%)",
		border: "none",
		}}>
		<div style={{background: "#f0f0f0", height: 500,
		clipPath: "polygon(0 70%, 100% 20%, 100% 0, 0 50%)",
		border: "none",
		}}/>
		</div>
		<div style={{
		background: "#0068a0", height: 600, 
		}}>
		  <div style={{position: "relative", 
		  width: "100%",
		  display: "flex",
		  marginTop: -150,
		  }}>
		  <Grid style={{background: "#0068a0"}} container spacing={0}>
			  <Grid item xs={1}/>
			  <Grid item xs={5}>
			  <Card1>
			  <img
			style={{
			maxWidth: "100%", height: 500, objectFit: "contain"}}
			src={"/static/images/city.jpg"} />
			  </Card1>
			  </Grid>
			  <Grid item xs={6}>
			  <Card2>
			  <h1
				  style={{fontSize: 36, textAlign: "center", color: "white", fontFamily: "Serif"}}
			  >Connect top investors with valuable enterprises.</h1>
			  <Button
			style={{width: 200, height: 60, background: "#666666"}}
			component={Link} to={"/en/about"}>
			<div style={{alignItems: "center"}}>
			<h1 style={{color: "white", textAlign: "center", fontSize: 24
			}}>Know More</h1>
			</div>
			</Button>
			  </Card2>
			  </Grid>
			  </Grid>
		  </div>
		</div>
		</div>
  
	  </div>
		  </MediaQuery>
		  <MediaQuery maxWidth={THRESHOLD}>
  
		<div style={{position: "relative", width: "100%", height: "100%"
		}}>
		<div style={{
		  marginTop: -110,
		position: "relative", width: "100%"}}>
  
		<Box style={{background: "#0068a0", height: 100, 
		clipPath: "polygon(100% 100%, 0% 100%, 100% 0)"}}/>
		<div style={{background: "#0068a0", marginTop: -60,
		clipPath: "polygon(0 100%, 100% 100%, 100% 0, 0 30%)",
		border: "none",
		}}>
		<div style={{background: "#f0f0f0", height: 200,
		clipPath: "polygon(0 70%, 100% 20%, 100% 0, 0 50%)",
		border: "none",
		}}/>
		</div>
		<div style={{
		background: "#0068a0", height: "auto", 
		}}>
		  <div style={{position: "relative", 
		  width: "100%", marginTop: -50
		  }}>
			  <Card1>
			  <img
			style={{
			width: "80%", objectFit: "cover"}}
			src={"/static/images/city.jpg"} />
			  </Card1>
  
			  <Card3>
			  <h1
				  style={{fontSize: 22, textAlign: "center", color: "white", fontFamily: "Serif"}}
			  >Connect top investors with valuable enterprises.</h1>
			<div style={{alignItems: "center"}}>
			  <Button
			style={{width: 150, height: 30, background: "#666666"}}
			component={Link} to={"/en/about"}>
			<h1 style={{color: "white", textAlign: "center", fontSize: 18
			}}>Know More</h1>
			</Button>
			</div>
			  </Card3>
		  </div>
		</div>
		</div>
  
	  </div>
		  </MediaQuery>
		  
		  <MediaQuery minWidth={THRESHOLD}>
		  <div style={{height: "auto", background: "#0068a0"}}>
		  <div style={{height: 10, background: "#0068a0"}}/>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 50, color: "white"}}> INVESTMENT </h1>
		  <div style={{height: "auto", background: "#0068a0"}}>
		  <div style={{height: 1, align: "center", width: "60%", background: "#ffffff",
		  marginLeft: "20%",
		  marginRight: "20%",
		  }}/>
		  <div style={{position: "relative", 
		  width: "100%",
		  display: "flex",
		  }}>
		  <div style={{width: "60%", align: "center", margin: "auto"}}>
		  <div style={{height: 50, background: "#0068a0"}}/>
		  <img
			style={{
			width: "80%", objectFit: "contain"}}
			src={"/static/images/earth.JPG"} />
			  <h1
				  style={{fontSize: 36, textAlign: "center", color: "white", fontFamily: "Serif"}}
			  >Avatar</h1>
			  <div style={{height: 50, background: "#0068a0"}}/>

			  
		  </div>
		  </div>
		  </div>
		  </div>
		  <div style={{height: 200, background: "#0068a0"}}/>
		  </MediaQuery>
		  <MediaQuery maxWidth={THRESHOLD}>
		  <div style={{height: "auto", background: "#0068a0"}}>
		  <div style={{height: 10, background: "#0068a0"}}/>
		  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 32, color: "white"}}> INVESTMENT </h1>
		  <div style={{height: "auto", background: "#0068a0"}}>
		  <div style={{height: 1, align: "center", width: "70%", background: "#ffffff",
		  marginLeft: "15%",
		  marginRight: "15%",
		  }}/>
		  <div style={{position: "relative", 
		  width: "100%",
		  }}>
			<div style={{height: 50, background: "#0068a0"}}/>
		  <div style={{width: "70%", align: "center", margin: "auto"}}>
			  <img
			style={{
			width: "100%", objectFit: "contain"}}
			src={"/static/images/earth.JPG"} />
			  <h1
				  style={{fontSize: 24, textAlign: "center", color: "white", fontFamily: "Serif"}}
			  >Avatar</h1>
			  <div style={{height: 100, background: "#0068a0"}}/>
		  </div>
		  </div>
		  </div>
		  </div>
		  </MediaQuery>
		</div>
		
		
	  </>
	);
  }
  