import React from "react";

import Typography from '@mui/material/Typography';
import { withStyles } from "@material-ui/core/styles";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from '@mui/material/Avatar';

import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import PlaylistPlayTwoToneIcon from "@material-ui/icons/PlaylistPlayTwoTone";
import PlaylistAddTwoToneIcon from "@material-ui/icons/PlaylistAddTwoTone";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';
import {THRESHOLD} from "./data";
import img_ja from "./images/ja.png";

import { makeStyles } from "@material-ui/core/styles";
import PageHead from "./PageHead";

const useStyles = makeStyles(theme => ({
  cardRootStyle: {
    minHeight: "auto",
    maxHeight: "auto",
  },
  cardMediaStyle: {
    paddingTop: "100%",
  }
}));

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}


const Card1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "transparent",
  border: "none",
	boxShadow: "none",
	marginTop: 30,
	marginBottom: 30,
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Card2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "transparent",
  borderColor: "black",
  borderWidth: 10,
	marginTop: 30,
	marginBottom: 30,
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function News(props) {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        position="relative"
        height="auto"
		className={backgroundClasses.main}
      >
	  <StyleRoot>
		<Switch>	
          <Route exact path="/news" component={Main}/>
		</Switch>	
	  </StyleRoot>
      </Box>
	</React.Fragment>
  );
}

News.propTypes = {
  imagePath: PropTypes.string
};

function Main(){
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();
	return(
	<>
	  <StyleRoot>
		<PageHead title="新聞資訊"/>

	<Box style={{background: "#f0f0f0", height: "100%"}}>
		<div 
			style={{
				width: "100%",
				height: "100%",
				animation: 'x 1s',
				animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}
		}>
	<Box 
		style={{
			width: "60%",
			alignItems: "center",
			justifyContent: 'center', 
			margin: "auto",
		}}
	>	
	<div style={{background: "#f0f0f0"}}>
		<div style={{height: 20, background: "#f0f0f0"}}/>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		  <div style={{height: 1, align: "center", width: "80%", background: "#000020",
		  marginLeft: "10%",
		  marginRight: "10%",
		  }}/>
		  </div>
	<div style={{height: 50}}/>
	<MediaQuery minWidth={THRESHOLD + 1}>
		<a style={{textDecoration: "none",}} target="_blank" href="https://www.ctwant.com/article/169242">
      <Grid container spacing={2}>
        <Grid item xs={4}>
		  <img 
		  style={{
		  maxWidth: "100%",
		  margin: "auto"
		  }}
		  src={"/static/images/finance.jpg"} />
        </Grid>

		<Grid item xs={8}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			
			}}>
			俄烏局勢緊張，台股大跌252點，投資人恐慌，葉總裁這樣說
			</h1>
        </Grid>
        </Grid>
		</a>
		<div style={{background: "#f0f0f0"}}>
		<div style={{height: 20, background: "#f0f0f0"}}/>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		  <div style={{height: 1, align: "center", width: "80%", background: "#000020",
		  marginLeft: "10%",
		  marginRight: "10%",
		  }}/>
		  </div>
	<div style={{height: 50}}/>
		<a style={{textDecoration: "none",}} target="_blank" href="https://www.ctwant.com/article/167940">
      <Grid container spacing={2}>
        <Grid item xs={4}>
		  <img 
		  style={{
		  maxWidth: "100%",
		  margin: "auto"
		  }}
		  src={"/static/images/finance.jpg"} />
        </Grid>

		<Grid item xs={8}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			}}>
			俄烏局勢緊張暫緩升息？天然氣美元結算？葉總裁這樣說
			</h1>
        </Grid>
      </Grid>
		</a>
	</MediaQuery>
	<MediaQuery maxWidth={THRESHOLD}>
		<a style={{textDecoration: "none",}} target="_blank" href="https://www.ctwant.com/article/167940">
      <Grid container spacing={2}>
        <Grid item xs={12}>
		  <img 
		  style={{
		  maxWidth: "100%",
		  margin: "auto"
		  }}
		  src={"/static/images/finance.jpg"} />
        </Grid>

		<Grid item xs={12}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			}}>
			俄烏局勢緊張，台股大跌252點，投資人恐慌，葉總裁這樣說
			</h1>
        </Grid>
        </Grid>
		</a>

		<div style={{background: "#f0f0f0"}}>
		<div style={{height: 20, background: "#f0f0f0"}}/>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		  <div style={{height: 1, align: "center", width: "80%", background: "#000020",
		  marginLeft: "10%",
		  marginRight: "10%",
		  }}/>
		   <div style={{height: 20, background: "#f0f0f0"}}/>
		  </div>
		 
	
		<a style={{textDecoration: "none",}} target="_blank" href="https://www.ctwant.com/article/167940">
      <Grid container spacing={2}>
        <Grid item xs={12}>
		  <img 
		  style={{
		  maxWidth: "100%",
		  margin: "auto"
		  }}
		  src={"/static/images/finance.jpg"} />
        </Grid>

		<Grid item xs={12}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			}}>
			俄烏局勢緊張暫緩升息？天然氣美元結算？葉總裁這樣說
			</h1>
        </Grid>
      </Grid>
		</a>
	</MediaQuery>
	<div style={{height: 50}}/>
	</Box>	
		</div>
	</Box>
	  </StyleRoot>
	</>

	);
}

function Test(){
	const styles = useStyles();
	return (
		<Box style={{
		background: "#222222",
		}}>
	  <StyleRoot>
        <div style={{
			animation: 'x 2s',
			animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}}>
      <Grid container spacing={2} style={{
		width: "60%",
			margin: "auto",
	  }}>
        <Grid item xs={2}/>
        <Grid item xs={4}>
			<Card1>
				<Card 
				classes={{
					root: styles.cardRootStyle
				}}
				sx={{ maxWidth: 500 }}
				>
				<CardMedia
					className={styles.cardMediaStyle}
					image="/static/images/LUI.JPG"
				/>
				</Card>
			</Card1>
        </Grid>
        <Grid item xs={6}>
			<Card2>
				<CardContent>
					<Typography gutterBottom color="#EEEEEE" variant="h5" component="div" align="left" style={{
					justifyContent: "center", 
					display: "flex",
					flexDirection: "column", height: 70, fontSize: 36, background: "black"}}>
					俄烏局勢緊張，台股大跌252點，投資人恐慌，葉總裁這樣說
					</Typography>
				</CardContent>
			</Card2>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{
		width: "60%",
			justifyContent: 'center', 
			margin: "auto",
	  }}>
        <Grid item xs={2}/>
        <Grid item xs={4}>
			<Card1>
				<Card 
				classes={{
					root: styles.cardRootStyle
				}}
				sx={{ maxWidth: 500 }}
				>
				<CardMedia
					className={styles.cardMediaStyle}
					image="/static/images/LUI.JPG"
				/>
				</Card>
			</Card1>
        </Grid>
        <Grid item xs={6}>
			<Card2>
				<CardContent>
					<Typography gutterBottom color="#EEEEEE" variant="h5" component="div" align="left" style={{
					justifyContent: "center", 
					display: "flex",
					flexDirection: "column", height: 70, fontSize: 36, background: "black"}}>
					俄烏局勢緊張暫緩升息？天然氣美元結算？葉總裁這樣說
					</Typography>
				</CardContent>
			</Card2>
        </Grid>
      </Grid>
        </div>
	  </StyleRoot>
	  </Box>
	);	
}


