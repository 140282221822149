import { makeStyles } from "@material-ui/core/styles";
import img from "./images/bg.jpg";
import img_light from "./images/bg_light.jpg";
import img_dark from "./images/bg_dark.jpg";
import img_white from "./images/white.jpg"; 
import img_city from "./images/city.jpg";

export const useButtonStyles = makeStyles({
  blue: {
    background: "#a994f0",
    border: 0,
    borderRadius: 6,
    color: "#white",
    height: 48,
    padding: "0 30px",
    margin: 32,
    fontSize: 30,
    fontFamily: "monospace",
  },
  none: {
    '&:hover': {
      background: "#f0f0f0",
    },
  },
  white: {
    color: "black",
    '&:hover': {
      color: '#3c52b2',
      background: "#f0f0f0",
    },
  },
  underline: {
    fontFamily: "monospace",
    fontSize: 50,
    color: "white",
    '&:hover': {
	  textDecoration: "underline",
      color: '#3c52b2',
    },
  },
});

export const useTypoStyles = makeStyles((theme) => ({
  underline: {
    fontFamily: "monospace",
    fontSize: 15,
    color: "white",
    '&:hover': {
	  textDecoration: "underline",
      color: '#3c52b2',
    },
  },
  header: {
    color: "#FFFFFF",
    fontSize: 90,
    fontFamily: `"LiHei Pro","黑體-繁","微軟正黑體", sans-serif`,
    fontStyle: "normal",
  },
  text: {
    color: "black",
    fontSize: 20,
    fontFamily: `"LiHei Pro","黑體-繁","微軟正黑體", sans-serif`,
    fontStyle: "normal",
  },
  sign: {
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: 20,
    fontFamily: "DejaVu Sans Mono, monospace",
    fontStyle: "normal",
  },
  subheader: {
    padding: theme.spacing(1),

    color: "white",
    fontSize: 60,
    fontFamily: "DejaVu Sans Mono, monospace",
    fontStyle: "normal",
  },
  songheader: {
	marginLeft: "5%",
	marginRight: "5%",
	marginTop: "1%",
	marginBottom: "1.7%",
    color: "white",
    fontSize: "3em",
    fontFamily: "DejaVu Sans Mono, monospace",
    fontStyle: "normal",
  },
}));

export const useBackgroundStyles = makeStyles({
  main: {
    backgroundcolor: "white",
    width: "100%",
  },
  light: {
    backgroundImage: `url(${img_white})`,
    width: "100%",
  },
  dark: {
    backgroundImage: `url(${img_white})`,
    width: "100%",
	height: "cover",
  },
  city: {
    backgroundImage: `url(${img_city})`,
	width: "cover",
	margin: "auto",
	backgroundSize: '1200px 600px',
	backgroundRepeat: 'no-repeat'
  },
});

