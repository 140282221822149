import React from "react";

import Typography from '@mui/material/Typography';
import { withStyles } from "@material-ui/core/styles";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from '@mui/material/Avatar';

import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import PlaylistPlayTwoToneIcon from "@material-ui/icons/PlaylistPlayTwoTone";
import PlaylistAddTwoToneIcon from "@material-ui/icons/PlaylistAddTwoTone";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';
import {THRESHOLD} from "./data";
import img_ja from "./images/ja.png";

import { makeStyles } from "@material-ui/core/styles";
import PageHead from "./PageHead";

const useStyles = makeStyles(theme => ({
  cardRootStyle: {
    minHeight: "auto",
    maxHeight: "auto",
  },
  cardMediaStyle: {
    paddingTop: "100%",
  }
}));

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}


const Card1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "transparent",
  border: "none",
	boxShadow: "none",
	marginTop: 30,
	marginBottom: 30,
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Card2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  background: "transparent",
  borderColor: "black",
  borderWidth: 10,
	marginTop: 30,
	marginBottom: 30,
  height: "100%",
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Column(props) {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        position="relative"
        height="auto"
		className={backgroundClasses.main}
      >
	  <StyleRoot>
		<Switch>	
          <Route path="/column/001" render={(props) => (
			<Posttext/>
			)} />	
          <Route exact path="/column" component={Main}/>
		</Switch>	
	  </StyleRoot>
      </Box>
	</React.Fragment>
  );
}

Column.propTypes = {
  imagePath: PropTypes.string
};

function Main(){
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();
	return(
	<>
	  <StyleRoot>
		<PageHead title="經理專欄"/>

	<Box style={{background: "#f0f0f0", height: "100%"}}>
		<div 
			style={{
				width: "100%",
				height: "100%",
				animation: 'x 1s',
				animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}
		}>
	<Box 
		style={{
			width: "60%",
			alignItems: "center",
			justifyContent: 'center', 
			margin: "auto",
		}}
	>	
	<div style={{background: "#f0f0f0"}}>
		<div style={{height: 20, background: "#f0f0f0"}}/>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		  <div style={{height: 1, align: "center", width: "80%", background: "#000020",
		  marginLeft: "10%",
		  marginRight: "10%",
		  }}/>
		  </div>
	<MediaQuery minWidth={THRESHOLD + 1}>
	<div style={{height: 50}}/>
      <Grid container spacing={2} style={{width: "80%", marginLeft: "10%", marginRight: "10%"}}>
		<Grid item xs={4}>
		<Button
		  className={buttonClasses.none}
		  style={{marginTop: "auto", width: "100%", height: "100%"}}
		  startIcon={<div>
		  <div style={{overflow: "hidden",
		  }}>
		  <img
		  style={{marginTop: 0, width: "100%", 
		  
		  objectFit: "cover"
		  }} 
		  src={"/static/images/finance.jpg"} />
		  </div>
		  </div>
		  }
		  component={Link} to={"/column/001"}/>
		</Grid>

		<Grid item xs={8}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			}}>
			⚠️
			</h1>
        </Grid>
      </Grid>
	</MediaQuery>
	<MediaQuery maxWidth={THRESHOLD}>
		<a style={{textDecoration: "none",}} target="_blank" href="https://www.ctwant.com/article/167940">
      <Grid container spacing={2}>
        <Grid item xs={12}>
		  <img 
		  style={{
		  maxWidth: "100%",
		  margin: "auto"
		  }}
		  src={"/static/images/finance.jpg"} />
        </Grid>

		<Grid item xs={12}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			}}>
			俄烏局勢緊張，台股大跌252點，投資人恐慌，葉總裁這樣說
			</h1>
        </Grid>
        </Grid>
		</a>

		<div style={{background: "#f0f0f0"}}>
		<div style={{height: 20, background: "#f0f0f0"}}/>
		<div style={{height: 10, background: "#f0f0f0"}}/>
		  <div style={{height: 1, align: "center", width: "80%", background: "#000020",
		  marginLeft: "10%",
		  marginRight: "10%",
		  }}/>
		   <div style={{height: 20, background: "#f0f0f0"}}/>
		  </div>
		 
	
		<a style={{textDecoration: "none",}} target="_blank" href="https://www.ctwant.com/article/167940">
      <Grid container spacing={2}>
        <Grid item xs={12}>
		  <img 
		  style={{
		  maxWidth: "100%",
		  margin: "auto"
		  }}
		  src={"/static/images/finance.jpg"} />
        </Grid>

		<Grid item xs={12}>
			<h1 style={{
			color: "black",
			fontSize: 25,
			textAlign: "left",
			}}>
			俄烏局勢緊張暫緩升息？天然氣美元結算？葉總裁這樣說
			</h1>
        </Grid>
      </Grid>
		</a>
	</MediaQuery>
	<div style={{height: 50}}/>
	</Box>	
		</div>
	</Box>
	  </StyleRoot>
	</>

	);
}

function Test(){
	const styles = useStyles();
	return (
		<Box style={{
		background: "#222222",
		}}>
	  <StyleRoot>
        <div style={{
			animation: 'x 2s',
			animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
			}}>
      <Grid container spacing={2} style={{
		width: "60%",
			margin: "auto",
	  }}>
        <Grid item xs={2}/>
        <Grid item xs={4}>
			<Card1>
				<Card 
				classes={{
					root: styles.cardRootStyle
				}}
				sx={{ maxWidth: 500 }}
				>
				<CardMedia
					className={styles.cardMediaStyle}
					image="/static/images/LUI.JPG"
				/>
				</Card>
			</Card1>
        </Grid>
        <Grid item xs={6}>
			<Card2>
				<CardContent>
					<Typography gutterBottom color="#EEEEEE" variant="h5" component="div" align="left" style={{
					justifyContent: "center", 
					display: "flex",
					flexDirection: "column", height: 70, fontSize: 36, background: "black"}}>
					俄烏局勢緊張，台股大跌252點，投資人恐慌，葉總裁這樣說
					</Typography>
				</CardContent>
			</Card2>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{
		width: "60%",
			justifyContent: 'center', 
			margin: "auto",
	  }}>
        <Grid item xs={2}/>
        <Grid item xs={4}>
			<Card1>
				<Card 
				classes={{
					root: styles.cardRootStyle
				}}
				sx={{ maxWidth: 500 }}
				>
				<CardMedia
					className={styles.cardMediaStyle}
					image="/static/images/LUI.JPG"
				/>
				</Card>
			</Card1>
        </Grid>
        <Grid item xs={6}>
			<Card2>
				<CardContent>
					<Typography gutterBottom color="#EEEEEE" variant="h5" component="div" align="left" style={{
					justifyContent: "center", 
					display: "flex",
					flexDirection: "column", height: 70, fontSize: 36, background: "black"}}>
					俄烏局勢緊張暫緩升息？天然氣美元結算？葉總裁這樣說
					</Typography>
				</CardContent>
			</Card2>
        </Grid>
      </Grid>
        </div>
	  </StyleRoot>
	  </Box>
	);	
}

function Posttext(){
	const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  const styles = useStyles();
	return(
		<div style={{background: "#f0f0f0"}}>
				<div style={{background: "#f0f0f0"}}>
			<div style={{height: 10, background: "#f0f0f0"}}/>
			<div style={{height: 10, background: "#f0f0f0"}}/>
			<div style={{height: 10, background: "#f0f0f0"}}/>
			  <h1 style={{position: "relative", fontFamily: "Serif", fontSize: 50, color: "#000020"}}> ⚠️ </h1>
			  
			  <div style={{height: 1, align: "center", width: "90%", background: "#000020",
			  marginLeft: "5%",
			  marginRight: "5%",
			  }}/>
			  </div>
			<div style={{background: "#f0f0f0", align: "center", width: "80%",
			marginLeft: "10%",
			marginRight: "10%",
			}}>
		<p style={{position: "relative", fontFamily: "Serif", fontSize: 20, color: "#000020"}}>

辛苦你了，恭喜你好不容易存到人生的第一桶金，其實不管是用什麼方式，都Congratulations，假設你可以100%運用這筆錢，以下的建議務必拿來操作，效果不會太差。
<br/>
<br/>
不同空間時間與條件下操作方式必須大幅調整才行，以免你愈來愈窮……我不會跟你扯購買力還是什麼通膨，這個議題太明顯也太多人討論，你們一定也清楚現在的第一桶金跟二十年前的質量完全不同，使用策略也要打掉重練。
<br/>
<br/>
這文章著重如何做長遠規劃，讓你做好賺真正大錢的事情準備，因為在這個時代，一百萬真的不算什麼，換算起來在台北市只能買一坪多，所以應該把這筆錢做更長遠打算。
<br/>
<br/>
這種集中火力買房買車的古老思想，大部分來自於爸媽還是華人一定要有自己的家的詭異習性，必須要在這個世代劃上逗號，等到成熟的時機到來再做打算，否則你的肝會爆掉然後還一事無成。
<br/>
<br/>
如果是一個有眼界的「經濟人」，應該把錢七三分，那比較小的三成，會形塑你的人生軌跡，你所做的努力，無限個日子的孤獨與付出，有天用奇蹟般的方式開花結果，雖然現在看起來微不足道。
<br/>
<br/>
首先，你必須拿一些部位來做自我學習，說真的，學校教育給你的東西有限，很多所需所學的，往往都是出社會實際操作後才發現的盲點，所以另外一所社會大學在你畢業時正式開學。
<br/>
<br/>
你必須下定決心投資你自己，因為這絕對是最值得而且投報率最高的經濟決策，而且沒有人可以掠奪你的技能，你可以學第三外語、更多線上函授還是考些證照，千萬不要省這種錢，覺得好像不用花，他們有天會成為你的痛處，得不償失。
<br/>
<br/>
第二，拿些錢請你朋友吃飯社交吧！你沒聽錯，這筆錢一定要規劃出來，不然你永遠跨不出這一步，捨不得花，只要有意識地使用這筆錢，這筆錢的回報絕對超過任何一檔ETF還是股票標地。
<br/>
<br/>
因為，出社會或是要幹大事，少不了志同道合的長輩朋友協助幫忙，而在飯局、觥籌交錯間往往就是引發創意的大好時機，如果不去加入、刻意製造這些機會，那很可能你的思想會乾涸，挑一些你佩服、欣賞的朋友還是mentor請他們吃飯跟他們學習吧，收穫有時候會大得誇張！（要謹慎交友呦）
<br/>
<br/>
最後，那筆大錢大家都清楚要放在股票還是風險比較高的標的，但我不是投顧老師也不想騙你的錢，我想給各位一個「債券梯」的概念，但此概念不僅適用於債券，你更要發揮想像力運用在各種scopes中。
<br/>
<br/>
舉最簡單的例子，一年期債券利率1%、兩年期2%，依此類推，最簡單的方式就是把錢分五份各買不同年期形成一個梯子（第一年拿到錢後再去買五年期的債券），然後第五年後你的利息收入就會正式上了軌道，就永遠是5%。
<br/>
<br/>
而且因為你有分配所以不用擔心每年沒錢用或是押注在特別哪年，以上的故事就只是一個小案例，更只是個比喻而已，請自行類推比較。
<br/>
<br/>
但上述故事的前期準備殺死了99.7%的人，所以如果你要成功，請務必努力，更重要的品質是「耐心」
<br/>
<br/>
當你努力買完火車票，確定方向後、匆匆忙忙地跨過月台跑上車，剩下的就是等列車發動（中間請不要跳上跳下）等一下下，讓火車把你帶往目的地，生命何嘗不是如此呢？
		</p>
		</div>
		</div>
	)
}
