import React from "react";

import Typography from '@mui/material/Typography';
import {useState} from "react";
import { withStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Sketch from "react-p5";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Player } from 'video-react';
import VideoPlayer from "react-background-video-player";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import {ReactP5Wrapper} from 'react-p5-wrapper';

import { styled } from '@mui/material/styles';


import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from "@material-ui/core/styles";
import img_chain from "./images/chain.jpg";
import img_earth from "./images/earth.JPG";
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';
import {THRESHOLD} from "./data";
import PageHead from "./PageHead";
import PropTypes from "prop-types";

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}



export function sketch (p) {
	p.setup = function () {
        p.createCanvas(window.innerWidth, 300);
    };

    p.draw = function () {
		p.background(255);
		p.noStroke();
		p.fill(100);
		p.rotate(-1);
		p.rect(-1.8 *  window.innerWidth, 0, 2 * window.innerWidth, 2 * window.innerWidth);
    };
}
export function sub (p) {
	p.setup = function () {
        p.createCanvas(window.innerWidth, 500);
		p.noLoop();
	};

    p.draw = function () {
		p.background(255);
		p.noStroke();
		p.fill(50);
		p.rect(0, 0, 0.4 * window.innerWidth, 2 * window.innerWidth);
		p.textSize(36);
		p.fill(200);
		p.text('連接頂尖投資者與高價值企業', 0.1 * window.innerWidth, 100);
		p.textSize(20);
		p.fill(100);
		p.text('綜觀近代資本主義三百年，即使在這個專業化分工的時代，\n股市仍然沒有專家，只有贏家和輸家，\n把自己的珍貴財富交給真正貼近股市的投資團隊才是在這個時代最正確的選擇。', 0.45 * window.innerWidth, 100);
    };
}

const useStyles = makeStyles(theme => ({
  cardRootStyle: {
	margin: "auto",
	width: "auto",
	height: "auto",
  },
  cardMediaStyle: {
    paddingTop: "100%",
  }
}));

const Card1 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  textAlign: 'center',
  opacity: 1,
  marginLeft: 100,
  background: '#2F4F4F',
  margin: "auto",
  color: theme.palette.text.secondary,
}));
const Card2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  textAlign: 'center',
  background: '#DDDDDD',
  margin: "auto",
  color: theme.palette.text.secondary,
}));
const Card3 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  textAlign: 'center',
  background: '#555555',
  margin: "auto",
  color: theme.palette.text.secondary,
}));
const Card4 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 0,
  height: "100%",
  textAlign: 'center',
  background: '#DDDDDD',
  margin: "auto",
  color: theme.palette.text.secondary,
}));

export default function Interests() {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();
  const styles = useStyles();
  /*
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, 200).parent(
      canvasParentRef
    );
    //p5.frameRate(this.fr);
  };
  const draw = (p5) => {
	p5.background(255);
	p5.noStroke();
	p5.fill(100);
	p5.rect(0, 0, 1000, 1000);
	p5.fill(200);
	p5.textSize(64);
	p5.text('關於勁揚', 100, 100);
  };
	*/
  
  return (
    <React.Fragment>
	  <StyleRoot>
	  <div>
	  	<Switch>	
          <Route  path="/investment/avartar" exact render={(props) => (
			<ItemPage/>
			)} /> 
		  <Route  path="/investment/" exact render={(props) => (
			<Main/>
			)} /> 
		</Switch>
		</div>
	  </StyleRoot>
	</React.Fragment>
  );
}

function Main(props){
	const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();
  const styles = useStyles();
	return (
		<>
			<PageHead title="投資組合"/>
			<div style={{background: "#f0f0f0"}}>
			
			<div style={{height: 100, background: "#f0f0f0"}}/>
	
			
			<div style={{width: "80%", height: 500, background: "black", margin: "auto"}}>
			
			<img
			  style={{ height: 500, opacity: 0.5, maxWidth: "100%", objectFit: "cover"}}
			  src={"/static/images/earth.JPG"} />
			</div> 
	
			<div style={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						}}>
				<Button
				className={buttonClasses.none}
				style={{width: "80%", height: "100%"}}
				component={Link} to={"/investment/avartar"}
				>
				<div style={{height: 500, marginTop: -500,
						position: "absolute",
						width: "100%",
						display: "flex",
						alignItems: "center",
						}}>
				  <h1 style={{
					margin: "auto",
					color: "white",
					fontSize: 50,
					fontFamily: "Serif",
					}}>阿凡達艦隊</h1>
				</div>
				</Button>
				
				</div>
				
			</div>
			<div style={{height: 100, background: "#f0f0f0"}}/>
			</>
	);
}

function Block(props){
	const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();
  const styles = useStyles();
	return(
	<>
	<StyleRoot>
		<MediaQuery minWidth={THRESHOLD + 1}>
				<Grid container
					spacing={0}
					alignContent="center"
					justifyContent="center"
					style={{marginTop: "2%"}}
				>
					<Grid
						container
						spacing={0}
						direction="column"
						alignContent="center"
						justifyContent="center"
						style={{background: "#0068a0", 
								opacity: 0.95,
								height: 100,
								width: "70%"}}
						>
							<Typography variant="body2" color="white" align="center" style={{fontSize: 28, fontFamily: "Serif"}}>
							{props.title}
							</Typography>
					</Grid>
				</Grid>
		</MediaQuery>
		<MediaQuery maxWidth={THRESHOLD}>
				<Grid container
					spacing={0}
					alignContent="center"
					justifyContent="center"
					style={{marginTop: "2%"}}
				>
					<Grid
						container
						spacing={0}
						direction="column"
						alignContent="center"
						justifyContent="center"
						style={{background: "#0068a0", 
								opacity: 0.95,
								height: 70,
								width: "90%"}}
						>
							<Typography variant="body2" color="white" align="center" style={{fontSize: 20, fontFamily: "Serif"}}>
							{props.title}
							</Typography>
					</Grid>
				</Grid>
		</MediaQuery>
		</StyleRoot>
	</>
	);
}


function ItemPage(){
	return (
		<StyleRoot>
		<div style={{
			animation: 'x 1s',
			animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
			background: "#f0f0f0",
			display: "flex",
			}}
			>
			<Box style={{width: "100%", margin: "auto", backgroundImage: `url(${img_earth})`,}}>
			<Box style={{
				opacity: 0.95,
				height: "auto", 
				width: "75%",
				marginBottom: "auto",
				marginTop: "auto",
				marginLeft: "auto",
				marginRight: "auto",
				textAlign: "left"
				}} 
				>
				<Box style={{
				display: "flex"
				}}>
		<MediaQuery minWidth={THRESHOLD + 1}>
		<div
			style={{width: "60%",
			margin: "auto",
			display: "flex",
			justifyContent: "center",
			}}
			>
				<h1 style={{
				color: "white",
				marginTop: 50,
				marginBottom: 50,
				
				marginLeft: 10,
				fontSize: 50,
				fontFamily: "Serif",
				}}>阿凡達艦隊</h1>
			
		</div>
			
		</MediaQuery>
		<MediaQuery maxWidth={THRESHOLD}>
		<div
			style={{width: "100%",
			margin: "auto",
			display: "flex",
			justifyContent: "center",
			}}
			>
				<h1 style={{
				color: "white",
				marginTop: 50,
				marginBottom: 50,
				marginLeft: 10,
				fontSize: 28,
				fontFamily: "Serif",
				}}>阿凡達艦隊</h1>
				</div>
		</MediaQuery>
		
				</Box>
				

				<h1 style={{
                marginLeft: 100,
                marginTop: -40,
                color: "#444444",
                fontSize: 36,
                fontFamily: `"LiHei Pro","黑體-繁","微軟正黑體", sans-serif`,
                fontStyle: "normal",
                }}>
                </h1>
				<Block title="聚焦台灣高價值成長企業"/>
				<Block title="以產、官、學面向全面分析"/>
				<Block title="為投資者控管風險帶來獲利"/>
				<Block title="靈活彈性的資產配置"/>
					<Grid
						container
						spacing={0}
						direction="column"
						alignContent="center"
						justifyContent="center"
						style={{ 
								height: 60,}}
						/>
			</Box>		
			</Box>
			
		</div>
		</StyleRoot>
	);
}