import React from "react";
import { Link } from "react-router-dom";
import Sketch from "react-p5";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useWindowDimensions from "./window.js";
import {useState, useEffect } from 'react';

	let vx = [];
	let sx = [];
	let lx = [];
	let vy = [];
	let sy = [];
	let ly = [];
	let IMG;
	//let W = window.innerWidth;
	//let H = window.innerHeight * 2;
	let HEADER1;
	let HEADER2;
	const time = 400;
	//const step =  W / time;
	let data = [];
	let count = 0;
	let posx, fy, c, infected, colors, maxH, l, f, INIT = 0;
	let step;
let vid;

export default function Back() {
	//let {height, width} = useWindowDimensions();
	const [p5, setP5] = useState();
	const preload = (p5) => {
		IMG = p5.loadImage('static/images/bitcoin.png');
		HEADER1 = p5.loadImage('static/images/head1.png');
		HEADER2 = p5.loadImage('static/images/head2.png');
	}
	
	useEffect(() => {
		window.addEventListener("resize", windowResized);
		return () => window.removeEventListener("resize", windowResized);
	});
	
	const setup = (p5, canvasParentRef) => {
		setP5(p5);
		p5.createCanvas(window.innerWidth, window.innerHeight).parent(canvasParentRef);
		//vid = p5.createVideo(["static/images/video.mov"]);
		//vid.hide();
		count = 0;
		INIT = 0;
		l = 0;
		for (let i = 0; i < p5.height; i+=100){
			vx.push(p5.random(1, 5));
			sx.push(0);
			lx.push(p5.random(300, p5.height));
		}
		for (let i = 0; i < p5.width; i+=100){
			vy.push(p5.random(1, 5));
			sy.push(0);
			ly.push(p5.random(300, p5.width));
		}
		//p5.frameRate(this.fr);
	};
	const draw = (p5) => {
		//
		p5.height = p5.width * 2 / 3;
		//
		posx = Float32Array.from({ length: 400 }, (_, i) => p5.map(i, 0, time, 0, p5.width));
		fy = _ => p5.map(_, 3, 0, p5.height / 4, 10);
		step = p5.width / time;
		p5.background(30);
		p5.fill(0);
		p5.noStroke(0);
		p5.rect(0, 0, p5.width / 2, p5.height);	
		p5.image(IMG, p5.width * 1 / 3, 0, p5.width * 2 / 3, p5.width * 2 / 3);	
		p5.stroke(0);
		p5.strokeWeight(3);
		p5.fill(0);
		let I = 0, J = 0;
		for (let i = 0; i < p5.height; i+=100) {
			sx[I] += vx[I];
			if(sx[I] - lx[I] >= p5.height){
				sx[I] = 0;
			}
			p5.line(i, Math.max(0, sx[I] - lx[I]), i, sx[I]);
			I++;
		}
		for (let j = 0; j < p5.width; j+=100) {
			sy[J] += vy[J];
			if(sy[J] - ly[J] >= p5.width){
				sy[J] = 0;
			}
			p5.line(Math.max(0, sy[J] - ly[J]), j, sy[J], j);
			J++;
		}
		//p5.image(IMG, 150, 50, 1254 * 1.5, 836 * 1.5);
		p5.triangle(0, 0, p5.width * 1 / 3, p5.height, p5.width * 1 / 2, 0);
		p5.fill(100, 80);
		//p5.rect(150, 150, 1254 * 1.5, 836 * 1.5);
		p5.noStroke();
		p5.fill(50);
		
		p5.noStroke();
        //p5.rect(50, 50, 500, 500);
        p5.fill(255);
        p5.textStyle(p5.BOLD);
        p5.textSize(70);
		//p5.text("勁旅揮師無止境 揚帆克猷富源魂", 500, 700)
        //p5.text("勁揚", 250, 250);
		p5.fill(30, 200);
		p5.triangle(0, 0, 0, p5.height / 4, p5.width, 0);
		p5.fill(200);
        p5.textStyle(p5.BOLD);
        p5.textSize(p5.width / 36);
        p5.text("成為投資人最堅實有力的後盾", p5.width / 20, p5.height / 10);
		//p5.image(HEADER1, 0, 0, 1200, 400);
		//p5.image(HEADER2, 100, 200, 1200, 400);
			
		l = data.length - 1;
    
		f = p5.frameCount;
		c = p5.cos(f * 0.008);
		infected = (p5.exp(-c*c/2.0) / p5.sqrt(p5.TWO_PI) / 0.2) + p5.map(p5.noise(f * 0.02), 0, 1, -1, 1);
    	
		if (time - count <= 80){
			infected -= (80 - (time - count)) / 20;
		}
	
    
		if (f & step) {
			data.push(infected);
			count += 1;
		} 

		for (let i = 0; i < l; i++) {
			let y1 = fy(data[i]) + p5.height / 4;
			let y2 = fy(data[i+1]) + p5.height / 4;
			let x1 = posx[i];
			let x2 = posx[i+1];
			p5.strokeWeight(0.2);
			p5.strokeWeight(3);
			p5.stroke(255);
			p5.line(x1, y1, x2, y2);
		}

		if (count > 1) {
			p5.ellipse(posx[l], fy(data[l]) + p5.height / 4, 4, 4);
		}

		if (count >= time){
			count = time
		}
		
	};
	function windowResized() {
		if (p5) {
			p5.resizeCanvas(window.innerWidth, window.innerHeight);
		}
	}

	return (
			<Sketch style={{marginTop: 60, marginBottom: -10}} preload={preload} setup={setup} draw={draw}/>
	);
};

