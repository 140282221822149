import React from "react";
import Box from "@material-ui/core/Box";
import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";

import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { makeStyles } from "@material-ui/core/styles";

import MediaQuery from 'react-responsive'

import {THRESHOLD} from "./data";

const styles = {
    pulse: {
      animation: 'x 0.5s',
      animationName: Radium.keyframes(pulse, 'pulse')
    },
    fadeInUp: {
      animation: 'x 0.5s',
      animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
    }
}

const useStyles = makeStyles(theme => ({
    cardRootStyle: {
      margin: "auto",
      width: "auto",
      height: "auto",
    },
    cardMediaStyle: {
      paddingTop: "100%",
    }
  }));

  /*
<video preload autoPlay loop muted playsinline
		style={{minHeight: "100%", width: "100%", objectFit: "contain"}}
	  >
        <source
          src="/static/images/videohd.mov"
          type="video/mp4"
        />
      </video>
  */

export default function PageHead(props){
	const buttonClasses = useButtonStyles();
  	const typoClasses = useTypoStyles();
  	const backgroundClasses = useBackgroundStyles();
  	const styles = useStyles();
	return (
		
		<StyleRoot>
			<MediaQuery minWidth={THRESHOLD + 1}>
		<div style={{height: 46, background: "#0068a0"}}/>
		</MediaQuery>
		<MediaQuery maxWidth={THRESHOLD }>
		<div style={{height: 24, background: "#0068a0"}}/>
		</MediaQuery>
			
		<div style={{
			animation: 'x 0.5s',
			animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
			}}>
				<MediaQuery minWidth={THRESHOLD + 1}>
			<Box style={{background: "black", height: 400, clipPath: "polygon(100% 0%, 15% 100%, 100% 100%)", opacity: 0.5,}}>
			<img
			style={{
				width: "100%", objectFit: "cover"}}
				src={"/static/images/pixta_85106858_L.jpg"}>
			</img>
			</Box>
			</MediaQuery>
			<MediaQuery maxWidth={THRESHOLD}>
			<Box style={{background: "black", height: 200, clipPath: "polygon(100% 0%, 15% 100%, 100% 100%)", opacity: 0.5,}}>
			<img
			style={{
				width: "100%", objectFit: "cover"}}
				src={"/static/images/pixta_85106858_L.jpg"}>
			</img>
			</Box>
			</MediaQuery>
			<MediaQuery minWidth={THRESHOLD + 1}>
			<Box style={{
				background: "#0068a0",
				height: 400,
				marginTop: -400,
				width: "100%", 
				alignItems: 'center', 
				justifyContent: 'center', 
				display: 'flex', 
				}} 
				id="header"
				>
				<h1 className={
				typoClasses.header}
				style={{fontFamily: "Serif"}}
				>
				{props.title}
				</h1>
			</Box>
			</MediaQuery>
			<MediaQuery maxWidth={THRESHOLD}>
			<Box style={{
				background: "#0068a0",
				height: 200,
				marginTop: -200,
				width: "100%", 
				alignItems: 'center', 
				justifyContent: 'center', 
				display: 'flex', 
				}} 
				id="header"
				>
				<h1 className={
				typoClasses.header}
				style={{fontSize: 50, fontFamily: "Serif"}}
				>{props.title}</h1>
			</Box>
			</MediaQuery>
		</div>
		</StyleRoot>
	);
}