import React from "react";
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive'
import MenuIcon from '@material-ui/icons/Menu';
import {THRESHOLD} from "./data";


import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PageHead from "./PageHead";

import PlayArrowTwoToneIcon from "@material-ui/icons/PlayArrowTwoTone";
import PlaylistPlayTwoToneIcon from "@material-ui/icons/PlaylistPlayTwoTone";
import PlaylistAddTwoToneIcon from "@material-ui/icons/PlaylistAddTwoTone";

import { useButtonStyles, useTypoStyles, useBackgroundStyles } from "./styles";


import { pulse, fadeInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

const styles = {
  pulse: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeInUp: {
	animation: 'x 0.5s',
	animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  }
}


function Text(){
	
	return (
	<>
	<h1 style={{textAlign: "left"}}
	>法律聲明和使用條款 </h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	一旦您造訪我們的網站（以下簡稱本網站），即表示同意並接受本網站的法律聲明和使用條款，包括所有與網站相關素材。我們可能隨時對這些法律作出修改與更新說明，如果您繼續訪問我們修改後的網站也將表示您將接受這些法律聲明和使用條款。如果您不接受同意這些法律聲明，請不要使用本網站。
	</p>
	<h1 style={{textAlign: "left"}}
	>拒絕邀約和建議</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	您知悉本網站的內容是一般內容，其信息和目的不構成出售或購買任何證券的邀約或承諾。要約或特定的投資機會只會在最後私募備忘錄，認購協議及其他相關文件中體現，這些文件載明了條款及條件，包括成為投資者的必要條件。 
	我們不通過本網站提供投資或其他建議，本網站的內容不被視為建議您購買、出售或持有任何證券或其他投資，或者是您追求任何投資風格或策略。如果您想尋求投資、會計、稅務或法律方面的意見，請根據個人情況諮詢私人顧問。
	</p>
	<h1 style={{textAlign: "left"}}
	>免責條款</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	我們出於善意製作本網站，盡一切努力與善良管理責任涵蓋關於本網站的準確與最新動態，本網站提供了一個基礎，我們對此免責。我們不保證網站上的信息準確、可靠、過時或錯誤，不保證本網站可以在任何時間或任何地點被訪問，不保證無病毒或其他有害成分。使用本網站和使用或依賴任何本網站的材料，需要自擔風險。
	</p>
	<h1 style={{textAlign: "left"}}
	>保密</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	我們對所有通過本網站的特定領域獲得的信息保密和專有，這包括所有投資信息和成果，提供材料，財務報表和其他通過此網站提供的信息。
	</p>
	<h1 style={{textAlign: "left"}}
	>內容修改</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	我們會在我們的自主範圍內修改或終止本網站的任何內容、任何部分、自主決定通知與否。
	</p>
	<h1 style={{textAlign: "left"}}
	>所有權</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	本網站包含之圖形、標誌、文章和其他材料等所有內容是我們的機構或其他機構財產，並受版權法和相關法律的保護。本網站裡所顯示的所有商標及標誌是其各自所有者的財產，該所有者與我們的機構也可能沒有關係。本網站不包含任何證書或者授權，暗自許諾、禁止或其他行為來使本網站的任何標誌或內容，沒有我們機構或其他第三方所有權者的書面許可均不得使用。
	</p>
	<h1 style={{textAlign: "left"}}
	>智慧財產權</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	基於尊重內容提供者之智慧財產權，本站對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。對該等內容之正確真偽，您宜自行判斷之。您若認為某些內容涉及侵權或有所不實，請逕向該內容提供者反應意見，尊重智慧財產權是您應盡的義務。
	</p>
	<h1 style={{textAlign: "left"}}
	>使用限制</h1>
	<p style={{textAlign: "left", fontSize: 20}}>
	使用者承諾絕不為任何非法目的或以任何非法方式使用本站，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務從事侵害他人權益或違法之行為。
	</p>
</>
);
}

export default function About() {
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoStyles();
  const backgroundClasses = useBackgroundStyles();

  return (
    <>
		<div style={{height: 46}}/>
	  <StyleRoot>
		<PageHead title="法律聲明"/>
        <div style={styles.fadeInUp}>
		  <Box width="80%" marginLeft="auto"  marginRight="auto">
		  <Text />
		  </Box>
		</div>
	  </StyleRoot>
    </>
  );
}
